// @flow
import React from 'react';
import { delimited } from '../../helpers/ApplicationHelper';
import IncomeModal from './IncomeModal';
import BlueArrowPng from '../../../../assets/images/views/questions/blue-arrow.png';
import GreenArrowPng from '../../../../assets/images/views/questions/green-arrow.png';

type Props = {
  taxReturnImage: string,
  taxWithholdingImage: string,
};

type State = {
  income: string,
  incomeError: ?string,
  investment: string,
  investmentError: ?string,
  gain: string,
  gainError: ?string,
  gainByOutsideStock: string,
  gainByOutsideStockError: ?string,
  showIncomeModal: boolean,
};

type AngelTax = {
  deduction: number,
  incomeTax: number,
  refund: number,
};
// 株式譲渡益に対する税率(復興特別所得税含む)
const S_TAX = 0.15315;
// 復興特別所得税率
const TAX_FOR_RECONSTRUCTION = 0.021;

export default class AngelTaxView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      income: '',
      incomeError: null,
      investment: '',
      investmentError: null,
      gain: '',
      gainError: null,
      gainByOutsideStock: '',
      gainByOutsideStockError: null,
      showIncomeModal: false,
    };
  }

  static lookUpTaxPercentage(income: number): number {
    if (income <= 1950000) {
      return 0.05;
    } else if (1950000 < income && income <= 3300000) {
      return 0.1;
    } else if (3300000 < income && income <= 6950000) {
      return 0.2;
    } else if (6950000 < income && income <= 9000000) {
      return 0.23;
    } else if (9000000 < income && income <= 18000000) {
      return 0.33;
    } else if (18000000 < income && income <= 40000000) {
      return 0.4;
    } else {
      return 0.45;
    }
  }

  static calcDeduction(tax: number): number {
    if (tax <= 0.05) {
      return 0;
    } else if (tax === 0.1) {
      return 97500;
    } else if (tax === 0.2) {
      return 427500;
    } else if (tax === 0.23) {
      return 636000;
    } else if (tax === 0.33) {
      return 1536000;
    } else if (tax === 0.4) {
      return 2796000;
    } else if (tax === 0.45) {
      return 4796000;
    } else {
      return 0;
    }
  }

  onChangeIncome(event: any) {
    this.setState({
      income: event.target.value,
      incomeError: this.errorMessageOnChange(event.target.value),
    });
  }

  onFocusIncome(event: any) {
    //先にコンマを取り除かないとincomeErrorに値が入ってしまうので、
    //先にコンマ取っておく
    let value = this.deleteComma(event.target.value);
    this.setState({
      income: value,
      incomeError: this.errorMessageOnChange(value),
    });
  }

  onBlurIncome(event: any) {
    this.setState({
      income: this.numberWithComma(event.target.value),
      incomeError: this.errorMessageOnChange(event.target.value),
    });
  }

  onChangeInvestment(event: any) {
    this.setState({
      investment: event.target.value,
      investmentError: this.errorMessageOnChange(event.target.value),
    });
  }

  onFocusInvestment(event: any) {
    let value = this.deleteComma(event.target.value);
    this.setState({
      investment: value,
      investmentError: this.errorMessageOnChange(value),
    });
  }

  onBlurInvestment(event: any) {
    this.setState({
      investment: this.numberWithComma(event.target.value),
      investmentError: this.errorMessageOnChange(event.target.value),
    });
  }

  onChangeGain(event: any) {
    this.setState({
      gain: event.target.value,
      gainError: this.errorMessageOnChange(event.target.value),
    });
  }

  onFocusGain(event: any) {
    let value = this.deleteComma(event.target.value);
    this.setState({
      gain: value,
      gainError: this.errorMessageOnChange(value),
    });
  }

  onBlurGain(event: any) {
    this.setState({
      gain: this.numberWithComma(event.target.value),
      gainError: this.errorMessageOnChange(event.target.value),
    });
  }

  onChangeGainByOutsideStock(event: any) {
    this.setState({
      gainByOutsideStock: event.target.value,
      gainByOutsideStockError: this.errorMessageOnChange(event.target.value),
    });
  }

  onFocusGainByOutsideStock(event: any) {
    let value = this.deleteComma(event.target.value);
    this.setState({
      gainByOutsideStock: value,
      gainByOutsideStockError: this.errorMessageOnChange(value),
    });
  }

  onBlurGainByOutsideStock(event: any) {
    this.setState({
      gainByOutsideStock: this.numberWithComma(event.target.value),
      gainByOutsideStockError: this.errorMessageOnChange(event.target.value),
    });
  }

  errorMessageOnChange(value: ?string): ?string {
    if (value == null || value === '') {
      return null;
    }
    if (!value.match(/^[0-9]+$/)) {
      return '半角数字を入力してください';
    }
    if (parseInt(value) >= parseInt(1000000000000)) {
      return '1,000,000,000,000未満の値を入力してください';
    }
    return null;
  }

  //入力ボックスからフォーカスが離れたときに、コンマを付加する
  numberWithComma(value: string): string {
    if (!value.match(/^[0-9]+$/)) {
      //元々の入力値が不正の場合は、何もしないで値を返す
      return value;
    }
    return delimited(Math.floor(parseInt(value)));
  }

  deleteComma(value: string): string {
    return value.replace(/,/g, '');
  }

  integerWithoutComma(value: string): number {
    if (value == '') {
      return 0;
    }
    return parseInt(this.deleteComma(value));
  }

  calcIncomeTax(): ?number {
    if (
      this.state.income === '' ||
      this.state.incomeError != null ||
      this.state.gainError != null ||
      this.state.gainByOutsideStockError != null
    ) {
      return null;
    }

    const income = this.integerWithoutComma(this.state.income);
    const gainTotal =
      this.integerWithoutComma(this.state.gain) +
      this.integerWithoutComma(this.state.gainByOutsideStock);
    const taxPercentage = AngelTaxView.lookUpTaxPercentage(
      income - this.baseDeductionByIncome(income),
    );
    const deduction = AngelTaxView.calcDeduction(taxPercentage);
    const taxAfterDeduction = Math.max(
      this.roundForTax(
        (income - this.baseDeductionByIncome(income)) * taxPercentage -
          deduction,
      ),
      0,
    );
    // 戻り値に復興特別所得税を加える
    const wkAmount = taxAfterDeduction * (TAX_FOR_RECONSTRUCTION * 1000);
    const reconstructionTax = wkAmount / 1000;

    return Math.max(
      taxAfterDeduction +
        this.roundForTax((gainTotal - Math.max(480000 - income, 0)) * S_TAX) +
        reconstructionTax,
      0,
    );
  }

  calcAngelTaxA(incomeTax: ?number): ?AngelTax {
    //investment, gain, gainByOutsideStockは空でもOKに変更した。
    //初期値に0を設定しないようにしたため。
    if (
      incomeTax == null ||
      this.state.income === '' ||
      this.state.incomeError != null ||
      this.state.investmentError != null ||
      this.state.gainError != null ||
      this.state.gainByOutsideStockError != null
    ) {
      return null;
    }

    const income = this.integerWithoutComma(this.state.income);
    const investment = this.integerWithoutComma(this.state.investment);
    const gainTotal =
      this.integerWithoutComma(this.state.gain) +
      this.integerWithoutComma(this.state.gainByOutsideStock);
    const baseDeduction = this.baseDeductionByIncome(income);
    // 基礎控除のみ引いて判定用
    const incomeExceptBaseDeduction = Math.max(income - baseDeduction, 0);
    const deductionA = Math.max(
      Math.min(investment, 8000000, income * 0.4) - 2000,
      0,
    );
    // 基礎控除＆優遇措置Aを引いて判定用
    const incomeExceptDeductionAandBase = Math.max(
      incomeExceptBaseDeduction - deductionA,
      0,
    );
    // 税率
    const taxPercentage = AngelTaxView.lookUpTaxPercentage(
      incomeExceptDeductionAandBase,
    );
    const deduction = AngelTaxView.calcDeduction(taxPercentage);
    // 譲渡益を除いた所得税(復興税未加算)
    const incomeTaxExceptGailTotal =
      this.roundForTax(incomeExceptDeductionAandBase * taxPercentage) -
      deduction;
    const incomeTaxA =
      incomeTaxExceptGailTotal +
      this.roundForTax((gainTotal - Math.max(480000 - income, 0)) * S_TAX);

    const reconstructionTax = Math.floor(
      incomeTaxExceptGailTotal * TAX_FOR_RECONSTRUCTION,
    );

    return {
      deduction: deductionA,
      incomeTax: incomeTaxA + reconstructionTax,
      refund: Math.max(incomeTax - (incomeTaxA + reconstructionTax), 0),
    };
  }

  calcAngelTaxB(incomeTax: ?number): ?AngelTax {
    //investment, gain, gainByOutsideStockは空でもOKに変更した。
    //初期値に0を設定しないようにしたため。
    if (
      incomeTax == null ||
      this.state.income === '' ||
      this.state.incomeError != null ||
      this.state.investmentError != null ||
      this.state.gainError != null ||
      this.state.gainByOutsideStockError != null
    ) {
      return null;
    }

    const income = this.integerWithoutComma(this.state.income);
    const investment = this.integerWithoutComma(this.state.investment);
    const gainTotal =
      this.integerWithoutComma(this.state.gain) +
      this.integerWithoutComma(this.state.gainByOutsideStock);
    const baseDeduction = this.baseDeductionByIncome(income);
    // 基礎控除のみ引いて判定用
    const incomeExceptBaseDeduction = Math.max(income - baseDeduction, 0);
    // 税率
    const taxPercentage = AngelTaxView.lookUpTaxPercentage(
      incomeExceptBaseDeduction,
    );
    const deduction = AngelTaxView.calcDeduction(taxPercentage);
    const deductionB = Math.min(
      Math.min(investment, gainTotal) +
        (income <= 480000 ? 480000 - income : 0),
      gainTotal,
    );
    // 譲渡益を除いた所得税(復興税未加算)
    const incomeTaxExceptGailTotal =
      this.roundForTax(incomeExceptBaseDeduction * taxPercentage) - deduction;

    const reconstructionTax = Math.floor(
      incomeTaxExceptGailTotal * TAX_FOR_RECONSTRUCTION,
    );

    const incomeTaxB =
      incomeTaxExceptGailTotal +
      this.roundForTax(
        Math.max(gainTotal - investment - Math.max(480000 - income, 0), 0) *
          S_TAX,
      ) +
      reconstructionTax;

    return {
      deduction: deductionB,
      incomeTax: incomeTaxB,
      refund: Math.max(incomeTax - incomeTaxB, 0),
    };
  }

  roundForTax(sourceNumber: ?number): number {
    if (sourceNumber == null) {
      return 0;
    }
    return Math.floor(sourceNumber / 100) * 100;
  }

  baseDeductionByIncome(income: ?number): number {
    if (income != null) {
      if (income <= 24000000) {
        return 480000;
      } else if (income <= 24500000) {
        return 320000;
      } else if (income <= 25000000) {
        return 160000;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  render() {
    const { taxReturnImage, taxWithholdingImage } = this.props;
    const {
      income,
      incomeError,
      investment,
      investmentError,
      gain,
      gainError,
      gainByOutsideStock,
      gainByOutsideStockError,
      showIncomeModal,
    } = this.state;
    const incomeTax = this.calcIncomeTax();
    const angelTaxA = this.calcAngelTaxA(incomeTax);
    const angelTaxB = this.calcAngelTaxB(incomeTax);

    return (
      <div className="angel-main-wrap">
        <h1 className="angel-title">
          エンジェル税制
          <br className="pc-none" />
          シミュレーター
        </h1>
        <div className="alert alert-primary alert-dismissible angel-alert-text">
          <b>所得税の計算について</b>
          <ul>
            <li>
              令和4年6月時点の税制を基にした概算のシミュレーションとなります。
            </li>
            <li>
              所得控除は基礎控除のみで、その他控除については考慮していません。
            </li>
            <li>所得税額には、復興特別所得税額(2.1%)が含まれております。</li>
            <li>
              株式譲渡は分離課税選択で税率は15.315%(所得税および復興特別所得税)です。
            </li>
            <li>
              基礎控除の計算については、総所得金額と合計所得金額が同一である前提で行っております。
            </li>
          </ul>
        </div>
        <div className="about-gross-income">
          <a
            className="sp-none"
            onClick={() => this.setState({ showIncomeModal: true })}
          >
            総所得金額とは？
          </a>
        </div>
        <div>
          <form className="top-form">
            <div
              className={
                incomeError != null
                  ? 'angel-form-input-block form-group income has-error'
                  : 'angel-form-input-block form-group income'
              }
            >
              <label className="control-label sp-none" htmlFor="income">
                総所得金額
              </label>

              <div className="pc-none sp-block">
                <label className="control-label pc-none" htmlFor="income">
                  総所得金額
                </label>
                <a
                  className="pc-none"
                  onClick={() => this.setState({ showIncomeModal: true })}
                >
                  総所得金額とは？
                </a>
              </div>

              <div className="angel-form-input-wrap">
                <input
                  id="income"
                  type="text"
                  className="form-control"
                  value={income}
                  onChange={e => this.onChangeIncome(e)}
                  onFocus={e => this.onFocusIncome(e)}
                  onBlur={e => this.onBlurIncome(e)}
                  placeholder="例, 10000000"
                />
                {incomeError && (
                  <span className="help-block error-message">
                    {incomeError}
                  </span>
                )}
              </div>
            </div>
            <div
              className={
                investmentError != null
                  ? 'angel-form-input-block form-group investment has-error'
                  : 'angel-form-input-block form-group investment'
              }
            >
              <label className="control-label" htmlFor="investment">
                エンジェル税制適用会社への投資額
              </label>
              <div className="angel-form-input-wrap">
                <input
                  id="investment"
                  type="text"
                  className="form-control"
                  value={investment}
                  onChange={e => this.onChangeInvestment(e)}
                  onFocus={e => this.onFocusInvestment(e)}
                  onBlur={e => this.onBlurInvestment(e)}
                  placeholder="例, 500000"
                />
                {investmentError && (
                  <span className="help-block error-message">
                    {investmentError}
                  </span>
                )}
              </div>
            </div>
            <div
              className={
                gainError != null
                  ? 'angel-form-input-block form-group gain has-error'
                  : 'angel-form-input-block form-group gain'
              }
            >
              <label className="control-label" htmlFor="gain">
                上場株式の売買による譲渡益
              </label>
              <div className="angel-form-input-wrap">
                <input
                  id="gain"
                  type="text"
                  className="form-control"
                  value={gain}
                  onChange={e => this.onChangeGain(e)}
                  onFocus={e => this.onFocusGain(e)}
                  onBlur={e => this.onBlurGain(e)}
                  placeholder="例, 1000000"
                />
                {gainError && (
                  <span className="help-block error-message">{gainError}</span>
                )}
                <p className="help-block pt-2 font-weight-normal">
                  ※上場株式の売買において譲渡損の場合は0を記入してください。
                  <br />
                  ※上場株式に関わる譲渡損失の繰越がない前提で記入してください。
                  <br />
                  ※株式譲渡は特定口座（源泉あり）で取引されていることが前提です。
                </p>
              </div>
            </div>
            <div
              className={
                gainByOutsideStockError != null
                  ? 'angel-form-input-block form-group gain-by-outside-stock has-error'
                  : 'angel-form-input-block form-group gain-by-outside-stock'
              }
            >
              <label className="control-label" htmlFor="gain-by-outside-stock">
                非上場株式の売買による譲渡益
              </label>
              <div className="angel-form-input-wrap">
                <input
                  id="gain-by-outside-stock"
                  type="text"
                  className="form-control"
                  value={gainByOutsideStock}
                  onChange={e => this.onChangeGainByOutsideStock(e)}
                  onFocus={e => this.onFocusGainByOutsideStock(e)}
                  onBlur={e => this.onBlurGainByOutsideStock(e)}
                  placeholder="例, 1000000"
                />
                {gainByOutsideStockError && (
                  <span className="help-block error-message">
                    {gainByOutsideStockError}
                  </span>
                )}
                <p className="help-block pt-2 font-weight-normal">
                  ※非上場株式の売買において譲渡損の場合は0を記入してください。
                  <br />
                  ※非上場株式に関わる譲渡損失の繰越がない前提で記入してください。
                </p>
              </div>
            </div>
            <div className="angel-form-input-block income-tax-border">
              <label className="control-label" htmlFor="gain">
                エンジェル税制未使用の場合の所得税額
              </label>
              <div className="value">
                {incomeTax != null && incomeTax >= 0
                  ? `${delimited(Math.floor(incomeTax))}円`
                  : ''}
              </div>
            </div>
          </form>
        </div>

        <div className="angel-pattern-wrap">
          <h2>シミュレーション結果</h2>
          <div className="angel-pattern-inr">
            <div className="angel-pattern-item">
              <h4 className="pattern-a">優遇措置Aを利用</h4>
              <div className="angel-pattern-item-col">
                <div className="angel-pattern-item-col-inr">
                  <form>
                    <div className="form-group">
                      <label>総所得から控除できる額</label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          angelTaxA != null && angelTaxA.deduction >= 0
                            ? `${delimited(Math.floor(angelTaxA.deduction))}円`
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <label>エンジェル税制適用時の所得税額</label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          angelTaxA != null && angelTaxA.incomeTax >= 0
                            ? `${delimited(Math.floor(angelTaxA.incomeTax))}円`
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </form>
                </div>
                <div className="arrow-block">
                  <img height="26" width="106" alt="" src={BlueArrowPng} />
                </div>
                <div className="income-tax">
                  <div className="value score-pattern-a">
                    <div className="score-text blue-icon">節税効果</div>
                    {angelTaxA != null && angelTaxA.refund >= 0 ? (
                      <div>
                        {delimited(Math.floor(angelTaxA.refund))}
                        <span className="score-yen">円</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="angel-pattern-item">
              <h4 className="pattern-b">優遇措置Bを利用</h4>
              <div className="angel-pattern-item-col">
                <div className="angel-pattern-item-col-inr">
                  <form>
                    <div className="form-group">
                      <label>総所得から控除できる額</label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          angelTaxB != null && angelTaxB.deduction >= 0
                            ? `${delimited(Math.floor(angelTaxB.deduction))}円`
                            : ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <label>エンジェル税制適用時の所得税額</label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          angelTaxB != null && angelTaxB.incomeTax >= 0
                            ? `${delimited(Math.floor(angelTaxB.incomeTax))}円`
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  </form>
                </div>
                <div className="arrow-block">
                  <img height="26" width="106" alt="" src={GreenArrowPng} />
                </div>
                <div className="income-tax">
                  <div className="value score-pattern-b">
                    <div className="score-text green-icon">節税効果</div>
                    {angelTaxB != null && angelTaxB.refund >= 0 ? (
                      <div>
                        {delimited(Math.floor(angelTaxB.refund))}
                        <span className="score-yen">円</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="angel-under-text">
            本エンジェル税制税負担軽減シミュレーターは、税務申告に使用する為ではなく、仮定条件に基づく概算額の試算やそれに基づく情報提供を唯一の目的としています。実際の申告や各種株式の取得または譲渡等、プラン実行の際には、税理士等の専門家にご相談下さい。
          </p>
        </div>

        <IncomeModal
          show={showIncomeModal}
          taxReturnImage={taxReturnImage}
          taxWithholdingImage={taxWithholdingImage}
          onClose={() => this.setState({ showIncomeModal: false })}
        />
      </div>
    );
  }
}
