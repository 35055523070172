// @flow
import 'bootstrap';
import $ from 'jquery';
import ReactOnRails from 'react-on-rails';
import ahoy from 'ahoy.js';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import '../config/sentry';

// IE11.0で 'Promise'が定義されていません というエラーが発生する
// それを解消するために追加した
// https://qiita.com/kazutosato/items/9d12d9402dedbc2ac896
// $FlowFixMe
-Promise;

window.jQuery = $;
window.$ = $;

// Rails
require('rails-ujs').start();
require('turbolinks').start();

// JQuery plugins
require('bootstrap-notify');
require('readmore-js');
require('tempusdominus-bootstrap-4');
// $FlowFixMe
require('jquery-jpostal-ja');

// remotipart
require('../lib/remotipart/jquery.iframe-transport.js');
require('../lib/remotipart/jquery.remotipart.js');

// summernote
require('summernote/dist/summernote-bs4');
require('summernote/lang/summernote-ja-JP');

ahoy.trackAll();

window.notify = (title, message, from, align, icon, type, animIn, animOut) => {
  // $FlowFixMe
  $.notify(
    {
      icon: icon,
      tutle: title,
      message: message,
    },
    {
      type: type,
      placement: {
        from: from,
        align: align,
      },
      animate: {
        enter: animIn,
        exit: animOut,
      },
    },
  );
};

window.views = {
  layouts: {
    AppView: require('../application/views/layouts/AppView.js').default,
    BundleView: require('../application/views/layouts/BundleView.js').default,
  },
  my: {
    users: {
      FormView: require('../application/views/my/users/FormView.js').default,
    },
    companies: {
      basics: {
        FormView: require('../application/views/my/companies/basics/FormView.js')
          .default,
      },
      irs: {
        FormView: require('../application/views/my/companies/irs/FormView.js')
          .default,
        EditorView: require('../application/views/my/companies/irs/EditorView.js')
          .default,
        TemplateView: require('../application/views/my/companies/irs/TemplateView.js')
          .default,
        OnlineMeetingView: require('../application/views/my/companies/irs/OnlineMeetingView.js')
          .default,
        OnlineVotingView: require('../application/views/my/companies/irs/OnlineVotingView.js')
          .default,
      },
      funding: {
        agreements: {
          FormView: require('../application/views/my/companies/funding/agreements/FormView.js')
            .default,
        },
      },
      wizard: {
        confirms: {
          ShowView: require('../application/views/my/companies/wizard/confirms/ShowView.js')
            .default,
        },
      },
    },
    investors: {
      FormView: require('../application/views/my/investors/FormView.js')
        .default,
      interviews: {
        FormView: require('../application/views/my/investors/interviews/FormView.js')
          .default,
      },
      corporates: {
        FormView: require('../application/views/my/investors/corporates/FormView.js')
          .default,
      },
      beneficial_owners: {
        FormView: require('../application/views/my/investors/beneficial_owners/FormView.js')
          .default,
      },
      transaction_people: {
        FormView: require('../application/views/my/investors/transaction_people/FormView.js')
          .default,
      },
      corporate_documents: {
        FormView: require('../application/views/my/investors/corporate_documents/FormView.js')
          .default,
      },
      specific_applications: {
        FormView: require('../application/views/my/investors/specific_applications/FormView.js')
          .default,
        users: {
          FormView: require('../application/views/my/investors/specific_applications/users/FormView.js')
            .default,
          SelectPatternView: require('../application/views/my/investors/specific_applications/users/SelectPatternView.js')
            .default,
          FileUploadView: require('../application/views/my/investors/specific_applications/users/FileUploadView.js')
            .default,
          FileUploadRenewView: require('../application/views/my/investors/specific_applications/users/FileUploadRenewView.js')
            .default,
          ConfirmView: require('../application/views/my/investors/specific_applications/users/ConfirmView.js')
            .default,
          RenewalConfirmView: require('../application/views/my/investors/specific_applications/users/RenewalConfirmView.js')
            .default,
        },
        corporates: {
          FormView: require('../application/views/my/investors/specific_applications/corporates/FormView.js')
            .default,
          SelectPatternView: require('../application/views/my/investors/specific_applications/corporates/SelectPatternView.js')
            .default,
          FileUploadView: require('../application/views/my/investors/specific_applications/corporates/FileUploadView.js')
            .default,
          ConfirmView: require('../application/views/my/investors/specific_applications/corporates/ConfirmView.js')
            .default,
          RenewalConfirmView: require('../application/views/my/investors/specific_applications/corporates/RenewalConfirmView.js')
            .default,
        },
      },
      orders: {
        MediaView: require('../application/views/my/investors/orders/MediaView.js')
          .default,
        cancel_reasons: {
          FormView: require('../application/views/my/investors/orders/cancel_reasons/FormView.js')
            .default,
        },
      },
      reports: {
        IndexView: require('../application/views/my/investors/reports/IndexView.js')
          .default,
      },
      peps: {
        FormView: require('../application/views/my/investors/peps/FormView.js')
          .default,
      },
      irs: {
        IndexView: require('../application/views/my/investors/irs/IndexView.js')
          .default,
        SearchView: require('../application/views/my/investors/irs/SearchView.js')
          .default,
      },
      annual_reports: {
        IndexView: require('../application/views/my/investors/annual_reports/IndexView.js')
          .default,
      },
      wizard: {
        confirms: {
          ShowView: require('../application/views/my/investors/wizard/confirms/ShowView.js')
            .default,
        },
      },
      trades: {
        IndexView: require('../application/views/my/investors/trades/IndexView.js')
          .default,
      },
      user_individuals: {
        FormView: require('../application/views/my/investors/wizard/user_individuals/FormView.js')
          .default,
        AgreementFormView: require('../application/views/my/investors/wizard/user_individuals/AgreementFormView.js')
          .default,
      },
    },
    identifications: {
      FormView: require('../application/views/my/identifications/FormView.js')
        .default,
      EkycView: require('../application/views/my/identifications/EkycView.js')
        .default,
      PreView: require('../application/views/my/identifications/PreView.js')
        .default,
      individuals: {
        FormView: require('../application/views/my/identifications/individuals/FormView.js')
          .default,
      },
    },
    passwords: {
      ShowView: require('../application/views/my/passwords/ShowView.js')
        .default,
    },
  },
  cms_contents: {
    ShowView: require('../application/views/cms_contents/ShowView.js').default,
  },
  companies: {
    general_meetings: {
      votes: {
        ShowView: require('../application/views/companies/general_meetings/votes/ShowView.js')
          .default,
      },
    },
    stock_exchanges: {
      stock_transfers: {
        FormView: require('../application/views/companies/stock_exchanges/stock_transfers/FormView.js')
          .default,
      },
    },
  },
  projects: {
    ShowView: require('../application/views/projects/ShowView.js').default,
    v0: {
      ShowView: require('../application/views/projects/v0/ShowView.js').default,
    },
    products: {
      orders: {
        contracts: {
          ShowView: require('../application/views/projects/products/orders/contracts/ShowView')
            .default,
        },
      },
    },
  },
  plus_projects: {
    IndexView: require('../application/views/plus_projects/IndexView.js')
      .default,
    FormView: require('../application/views/plus_projects/FormView.js').default,
  },
  quits: {
    FormView: require('../application/views/quits/FormView.js').default,
  },
  users: {
    registrations: {
      FormView: require('../application/views/users/registrations/FormView')
        .default,
    },
  },
  pages: {
    FundingView: require('../application/views/pages/FundingView').default,
    UserGuideView: require('../application/views/pages/UserGuideView').default,
    CampaignView: require('../application/views/pages/CampaignView').default,
    RadioContentView: require('../application/views/pages/RadioContentView')
      .default,
    StatsView: require('../application/views/pages/StatsView').default,
    AboutShareholderCommunityView: require('../application/views/pages/AboutShareholderCommunityView')
      .default,
  },
  shareholder_communities: {
    IndexView: require('../application/views/shareholder_communities/IndexView')
      .default,
    ShowView: require('../application/views/shareholder_communities/ShowView')
      .default,
    PreDownloadView: require('../application/views/shareholder_communities/PreDownloadView')
      .default,
    TypeRatio: require('../application/views/shareholder_communities/TypeRatio')
      .default,
    trades: {
      FormView: require('../application/views/shareholder_communities/trades/FormView')
        .default,
    },
  },
  white_papers: {
    downloads: {
      FormView: require('../application/views/white_papers/downloads/FormView')
        .default,
    },
  },
};

ReactOnRails.register({
  ViewsCompaniesIndexView: require('../application/views/companies/IndexView.js')
    .default,
  ViewsMyInvestorsBankView: require('../application/views/my/investors/BankView.js')
    .default,
  ViewsProjectsIndexView: require('../application/views/projects/IndexView.js')
    .default,
  ViewsQuestionsAngelTaxView: require('../application/views/questions/AngelTaxView.js')
    .default,
  ViewsMyCompaniesKyccsCompaniesShowView: require('../application/views/my/companies/kyccs/companies/ShowView.js')
    .default,
  ViewsMyCompaniesKyccsCustomersShowView: require('../application/views/my/companies/kyccs/customers/ShowView.js')
    .default,
  ViewsMyInvestorsSpecificApplicationsFileUploadView: require('../application/views/my/investors/specific_applications/FileUploadView.js')
    .default,
  ViewsMyInvestorsDemandQuestionairesSelectCompaniesToSellView: require('../application/views/my/investors/shareholder_communities/demand_questionnaires/SelectCompaniesToSellView.js')
    .default,
  ViewsMyInvestorsDemandQuestionairesSelectCompaniesToBuyView: require('../application/views/my/investors/shareholder_communities/demand_questionnaires/SelectCompaniesToBuyView.js')
    .default,
  ViewsMyInvestorsDemandQuestionairesInputOtherVentureCompaniesView: require('../application/views/my/investors/shareholder_communities/demand_questionnaires/InputOtherVentureCompaniesView.js')
    .default,
  ViewsMyinvestorsWizardUserIndividualsBankView: require('../application/views/my/investors/wizard/user_individuals/BankView.js')
    .default,
});
